// eslint-disable-next-line import/prefer-default-export
export const getWindowSize = () => ({
  width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
});

export const numberToInternational = (number: string, code: string) => {
  const fixedNumber = number.replaceAll(' ', '');
  const codeIndex = fixedNumber.indexOf(code);
  if (codeIndex === 0) {
    return fixedNumber;
  }
  if (fixedNumber[0] === '0') {
    return `${code}${fixedNumber.substr(1)}`;
  }
  return `${code}${fixedNumber}`;
};

import React from 'react';

const Footer = () => (
  <div className="footer social-media-group">
    <a href="https://www.instagram.com/graze.burger/">
      <img alt="@graze.burger" src="instagram.png?v=1.0.0" height="80" width="80" />
    </a>
    <a href="https://vm.tiktok.com/ZSJd56jcY/">
      <img alt="@graze.burger" src="tiktok.png?v=1.0.0" height="80" width="80" />
    </a>
  </div>
);

export default Footer;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import HomePage from './pages/Home';
// import FoodSafetyPage from './pages/Safety/FoodSafety';
import CallUsPage from './pages/CallUs';

const routes: React.FunctionComponent = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    {/* <Route path="/food-safety" exact component={FoodSafetyPage} /> */}
    <Route path="/call-us" exact component={CallUsPage} />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default routes;

import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <Link role="button" to="/" className="special-link">
    <img src="/graze.png" alt="graze logo" className="graze-logo" />
  </Link>
);

export default Header;

/* eslint-disable react/style-prop-object */
import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import Footer from '../components/Footer';
import { numberToInternational } from '../utils';
import Header from '../components/Header';

const GrazeCallButton: FunctionComponent<GrazeCallButton> = ({ number }) => (
  <a role="button" href={`tel:${numberToInternational(number, '00961')}`} className="special-link">
    <Button variant="contained" className="graze-button">
      <div className="graze-text">{number}</div>
    </Button>
  </a>
);

const CallUsPage = () => (
  <div className="home-page">
    <div className="button-group">
      <Header />
      <GrazeCallButton number="01 364 608" />
      <GrazeCallButton number="01 364 609" />
      <GrazeCallButton number="03 364 666" />
      <Footer />
    </div>
  </div>
);

export default CallUsPage;
